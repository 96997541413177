
import { getV2Route } from '@/service/shared.http'
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'v2-cta',
	props: {
		path: String,
	},
	setup() {
		return {
			getV2Route,
		}
	},
})

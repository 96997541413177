
import { defineComponent, computed } from 'vue'

/**
 * Returns a closed interval in [start, end].
 */
function range(start: number, end: number): number[] {
	const result: number[] = []
	for (let i = start; i <= end; i++) {
		result.push(i)
	}
	return result
}

export default defineComponent({
	name: 'myndshft-pagination',
	props: {
		currentPage: {
			type: Number,
			default: 1,
		},
		totalPages: {
			type: Number,
			default: 1,
		},
		urlTemplate: {
			type: Function,
			default: () => '#',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		disabledTooltip: {
			type: String,
			default: 'This control is disabled',
		},
	},
	setup(props) {
		const showPrevious = computed(() => props.currentPage !== 1)
		const showNext = computed(() => props.currentPage < props.totalPages)
		const showLeftEllipsis = computed(() => props.currentPage > 5)
		const showRightEllipsis = computed(
			() => props.currentPage < props.totalPages - 4
		)

		const leftRange = computed(() => {
			return range(props.currentPage - 3, props.currentPage - 1).filter(
				page => page > 1
			)
		})

		const rightRange = computed(() => {
			return range(props.currentPage + 1, props.currentPage + 3).filter(
				page => page < props.totalPages
			)
		})

		return {
			showPrevious,
			showNext,
			showLeftEllipsis,
			showRightEllipsis,
			leftRange,
			rightRange,
		}
	},
})

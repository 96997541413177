
import { defineComponent, SetupContext, computed, PropType } from 'vue'

type Option = { text: string; value: string }

export default defineComponent({
	name: 'search-select',
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		searchQuery: {
			type: String,
			default: '',
		},
		searchType: { type: String, required: true },
		searchOptions: Array as PropType<Option[]>,
	},
	setup(props, context: SetupContext) {
		const query = computed({
			get(): string {
				return props.searchQuery
			},
			set(newValue: string): void {
				context.emit('update:searchQuery', newValue)
			},
		})
		const type = computed({
			get(): string {
				return props.searchType
			},
			set(newValue: string): void {
				context.emit('update:searchType', newValue)
			},
		})

		return {
			query,
			type,
		}
	},
})

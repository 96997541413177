
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		showDialog: Boolean,
		title: String,
		width: { type: Number, default: 700 },
		maxWidth: { type: Number, default: 700 },
		saveButtonText: { type: String, default: 'Confirm' },
	},
	setup() {
		return {}
	},
})

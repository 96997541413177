
import { defineComponent } from 'vue'

import ClientList from '@/components/client/client-list.vue'

export default defineComponent({
	components: {
		[ClientList.name]: ClientList,
	},
	setup() {
		return {}
	},
})

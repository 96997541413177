
import { Ref, computed, defineComponent, PropType } from 'vue'
import MyndshftTableCell from '@/components/table/cell.vue'

import { TableColumn } from './types'
import { useSorting } from './features/sortable'

type FilterBy = (e: object) => boolean

export default defineComponent({
	name: 'myndshft-table',
	components: {
		[MyndshftTableCell.name]: MyndshftTableCell,
	},
	props: {
		columns: {
			type: Array as PropType<TableColumn<object>[]>,
			default: () => [],
		},
		data: {
			type: Array,
			default: () => [],
		},
		filterBy: {
			type: Function,
		},
		hoverable: {
			type: Boolean,
			default: false,
		},
		sortable: {
			type: Boolean,
			default: false,
		},
		customId: String,
		loader: Boolean,
		height: {
			type: [String, Number],
			default: 300,
		},
	},
	setup(props) {
		const isLoading = computed(() =>
			props.loader !== undefined ? props.loader : false
		)
		const data = computed(() => props.data)

		const filteredData = computed(() => {
			const dataCopy = [...(data.value as Array<any>)]
			return props.filterBy
				? dataCopy.filter(props.filterBy as FilterBy)
				: dataCopy
		})

		const { sortColumnIndex, sortDirection, updateSortColumn, sortedData } =
			useSorting(props.columns as Array<any>, filteredData as Ref<Array<any>>)

		const styles = {
			headCellStyle(column: any, index: number) {
				return {
					'is-column-sorted': props.sortable && index === sortColumnIndex.value,
					'is-sortable': props.sortable && !column.sortIsDisabled,
				}
			},
			bodyRowStyle: computed(() => {
				return {
					'is-hoverable': props.hoverable,
					'is-selected': true,
				}
			}),
			sortIconStyle(index: number) {
				return {
					'is-invisible': props.sortable && index !== sortColumnIndex.value,
					'sort-icon': true,
				}
			},
			scrollStyle: computed(() => {
				const type = typeof props.height === 'number' ? 'px' : ''

				return {
					'overflow-y': props.height ? 'auto' : 'initial',
					maxHeight:
						props.height && data.value.length ? props.height + type : 'initial',
				}
			}),
		}

		const noItemsMessage = 'There are no items'

		return {
			sortColumnIndex,
			sortDirection,
			updateSortColumn,
			filteredData,
			sortedData,
			isLoading,
			noItemsMessage,
			...styles,
		}
	},
})

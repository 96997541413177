
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'myndshft-table-cell',
	props: {
		width: {
			type: String,
			default: 'auto',
		},
	},
})

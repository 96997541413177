
import { defineComponent, ref, watch, computed, onMounted } from 'vue'
import queryString from 'query-string'
import { toast, Alert } from '@/dependencies/sweet-alert'

import { useRouter } from '@/utils/composables'
import { getV2Route } from '@/service/shared.http'
import { ActiveStatus } from '@myndshft/types-mc-api'
import { fetchClientList, bulkUpdateClients } from '@/service/client.http'

import {
	IClientDetails,
	IClientListResults,
	IClientTransaction,
} from 'server/api/models/fe/client'
import { IClientQueryParams } from '@/models/client/client'
import { ClientTransactionTypes } from '@server/api/models/fe/client'

import { MyndshftPanel } from '@myndshft/panel'
import MyndshftTable from '@/components/table/table.vue'
import MyndshftTableCell from '@/components/table/cell.vue'
import MyndshftPagination from '@/components/table/pagination.vue'
import SearchSelect from '@/components/search/search-select.vue'
import ConfirmationDialog from '@/components/shared/dialog/confirmation-dialog.vue'
import V2Cta from '@/components/shared/v2-cta.vue'

export default defineComponent({
	name: 'client-list',
	components: {
		[MyndshftTable.name]: MyndshftTable,
		[MyndshftTableCell.name]: MyndshftTableCell,
		[MyndshftPagination.name]: MyndshftPagination,
		[MyndshftPanel.name]: MyndshftPanel,
		SearchSelect,
		ConfirmationDialog,
		V2Cta,
	},
	setup() {
		const router = useRouter()

		const limit = ref(50)
		const page = ref(1)
		const query = ref('')
		const totalPages = ref(0)
		const totalCount = ref(0)
		const currentCount = ref(0)
		const data = ref<IClientDetails[]>([])
		const isLoading = ref(false)
		const columns = [
			{
				value: 'name',
				text: 'Provider Organization',
				width: '30%',
			},
			{
				value: 'fullName',
				text: 'Provider Name',
				width: '30%',
			},
			{
				value: 'transactions',
				text: 'Transactions',
			},
			{
				value: 'npi',
				text: 'NPI Number',
			},
			{
				value: 'tenant',
				text: 'Tenant',
				width: '115px',
			},
			{
				value: 'status',
				text: 'Status',
				width: '115px',
			},
		]
		const searchOptions = [
			{ text: 'Provider Organization', value: 'name' },
			{ text: 'Last Name', value: 'lastName' },
		]
		const searchType = ref(searchOptions[0].value)
		const statusItems = [
			{ text: 'Active', value: ActiveStatus.ACTIVE },
			{ text: 'Inactive', value: ActiveStatus.INACTIVE },
			{ text: 'All', value: 'ALL' },
		]
		const selectedStatus = ref<string>(ActiveStatus.ACTIVE)
		const selectedClients = ref([])
		const showStatusDialog = ref(false)
		const dialogPage = ref(0)
		const dialogClientStatus = ref(ActiveStatus.ACTIVE)
		const disableClientUpdateDialog = computed(
			() => !selectedClients.value.length
		)
		const dialogStatusOptions = [
			{ text: 'Active', value: ActiveStatus.ACTIVE },
			{ text: 'Inactive', value: ActiveStatus.INACTIVE },
		]

		const handleCloseDialog = () => {
			dialogPage.value = 0
			showStatusDialog.value = false
		}

		const handleStatusDialogSave = () => {
			if (dialogPage.value === 0) {
				dialogPage.value = 1
			} else {
				showStatusDialog.value = false
				const clientList = selectedClients.value.map((client: any) => client.id)
				bulkUpdateClients(['status'], clientList, dialogClientStatus.value)
					.then(() => {
						selectedClients.value = []
						handleFetchClientList()
						toast({
							type: Alert.SUCCESS,
							title: `Successfully updated all client statuses`,
							timer: 5000,
						})
					})
					.catch(() => {
						toast({
							type: Alert.ERROR,
							title: `Failed to update client statuses`,
							timer: 5000,
						})
					})
					.finally(() => {
						dialogPage.value = 0
					})
			}
		}

		const handleCreateClient = () => {
			router.push('/client/create')
		}

		const getAcronym = (name: any) => {
			switch (name) {
				case ClientTransactionTypes.ELIGIBILITY:
					return 'E'
				case ClientTransactionTypes.PATIENT_RESPONSIBILITY:
					return 'PFR'
				case ClientTransactionTypes.PRIOR_AUTH_DETERMINATION:
					return 'PAR'
				case ClientTransactionTypes.PRIOR_AUTH_SUBMISSION:
					return 'PAS'
				case ClientTransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION:
					return 'RXS'
				default:
					return 'NA'
			}
		}

		const filterTransactions = (transactions: IClientTransaction[]) => {
			return (
				transactions?.filter(t => {
					return (
						t.name !== ClientTransactionTypes.PBM_BENEFITS_ELIGIBILITY &&
						t.name !== ClientTransactionTypes.PBM_PATIENT_RESPONSIBILITY
					)
				}) || []
			)
		}

		const filterSearch = () => {
			const newQuery = queryString.stringify(
				{
					limit: limit.value,
					page: 1,
					query: query.value,
					type: searchType.value,
					status: selectedStatus.value,
				},
				{ skipEmptyString: true }
			)
			const prevQuery = queryString.stringify(router.app.$route.query, {
				skipEmptyString: true,
			})
			if (newQuery !== prevQuery) {
				router.push(`/client/list?${newQuery}`)
			}
		}

		const clearAll = () => {
			limit.value = 50
			page.value = 1
			query.value = ''
			searchType.value = 'name'
			selectedStatus.value = ActiveStatus.ACTIVE
			filterSearch()
		}

		const urlTemplate = (index: number) => {
			const qs = queryString.stringify({
				limit: limit.value,
				page: index,
				query: query.value,
				type: searchType.value,
				status: selectedStatus.value,
			})
			return `/client/list?${qs}`
		}

		const handleFetchClientList = () => {
			isLoading.value = true
			const queryOptions: IClientQueryParams = {
				perPage: limit.value,
				page: page.value,
				query: query.value,
				type: searchType.value,
				status: selectedStatus.value === 'ALL' ? '' : selectedStatus.value,
			}
			fetchClientList(queryOptions)
				.then((results: IClientListResults) => {
					data.value = results.clients
					totalPages.value = results.totalPages
					currentCount.value =
						page.value * limit.value - limit.value + results.clients.length
					totalCount.value = results.total
				})
				.catch(() => {
					toast({
						type: Alert.ERROR,
						title: `Failed to fetch client list`,
						timer: 5000,
					})
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		onMounted(() => {
			handleFetchClientList()
		})

		watch(
			() => router.app.$route.query,
			routeQuery => {
				limit.value = Number(routeQuery.limit) || 50
				page.value = Number(routeQuery.page) || 1
				query.value = (routeQuery.query as string) || ''
				searchType.value = (routeQuery.type as string) || 'name'
				selectedStatus.value =
					(routeQuery.status as string) || ActiveStatus.ACTIVE

				handleFetchClientList()
			},
			{ deep: true }
		)

		return {
			page,
			query,
			columns,
			data,
			totalPages,
			searchOptions,
			searchType,
			isLoading,
			currentCount,
			totalCount,
			selectedStatus,
			statusItems,
			ActiveStatus,
			selectedClients,
			dialogClientStatus,
			disableClientUpdateDialog,
			dialogStatusOptions,
			showStatusDialog,
			dialogPage,
			clearAll,
			urlTemplate,
			filterSearch,
			filterTransactions,
			handleCreateClient,
			getAcronym,
			getV2Route,
			handleCloseDialog,
			handleStatusDialogSave,
		}
	},
})
